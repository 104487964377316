<template>
    <div class="three-component" v-loading="[formLoading, true]">
        <div class="illustrate">
            <iconpark-icon class="illustrate-icon" :size="16" name="jinggao"></iconpark-icon>
            <span
                >说明：系统提供以下费用项的默认值，您可根据公司项目进行相关费用的单价调整，也可不进行调整</span
            >
        </div>
        <EditTable ref="editTableRef" :columns="columns" :dataSource="filterDataSource(dataSource)"></EditTable>
        <div class="handle-submit-group">
            <a-button
                v-if="route.query.type !== 'edit'"
                v-softDog="{
                    callback: onBeforeSubmitForm,
                    params: 1,
                    packageType: PACKAGE_TYPE.default,
                }"
                >保存草稿</a-button
            >
            <a-button
                class="step-next"
                v-softDog="{
                    callback: onBeforeSubmitForm,
                    params: 2,
                    packageType: PACKAGE_TYPE.default,
                }"
                >一键生成报告</a-button
            >
        </div>
        <projectPackage
            :TipsDialogVisible="TipsDialogVisible"
            :messageType="messageType"
            @changeShowKey="TipsDialogVisible = false"
        ></projectPackage>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted, onUnmounted, h } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import EditTable from '@/components/editTable/EditTable.vue';
    import {
        getThreeStepParam, // 获取第三步骤默认值
    } from '@/api/modules/wind/threeStep';
    import { saveFormData, updateFormData, selectProjectInfoById } from '@/api/modules/wind/firstStep';
    import { checkAdjustProjectIsDeductTimes } from '@/api/modules/project';
    import { message } from 'ant-design-vue';
    import eventBus from '@/utils/eventBus';
    import projectPackage from '@/components/projectPackage/projectPackage.vue';
    import { checkPackageEffective } from '@/api/modules/reportIndex.js';
    import { PACKAGE_TYPE } from '@/assets/js/static.js';
    import { Modal } from 'ant-design-vue';
    const editTableRef = ref(null);
    // 提交loading
    const formLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const TipsDialogVisible = ref(false);
    const messageType = ref(1);
    // 数据源常量
    const dataSource = ref([
        {
            name: '项目资金比例',
            dataIndex: 'fundingRatio',
            unit: '%',
            default: 20,
            price: null,
            min: 20,
            max: 100,
        },
        {
            name: '风电机组5MW',
            dataIndex: 'windGroupJson',
            unit: '元/kW',
            default: '--',
            price: null,
            min: 1300,
            max: 1800,
            isEdit: true,
        },
        {
            name: '塔筒387.21t',
            dataIndex: 'towerJson',
            unit: '元/t',
            default: '--',
            price: null,
            min: 9800,
            max: 10800,
            isEdit: true,
        },
        {
            name: '主变压器SZ18-100000/110',
            dataIndex: 'boosterJson',
            unit: '元/台',
            default: '--',
            price: null,
            min: 20,
            max: 100,
            isEdit: true,
        },
        // {
        //     name: '110kV GIS组合电器',
        //     dataIndex: 'fundingRatio',
        //     unit: '元/套',
        //     default: 20,
        //     price: null,
        //     min: 20,
        //     max: 100,
        //     isEdit: true,
        // },
        {
            name: `无功补偿装置SVG（±20Mvar，35kV）`,
            dataIndex: 'svgGroupJson',
            unit: '元/套',
            default: '--',
            price: null,
            min: 20,
            max: 100,
            isEdit: true,
        },
        {
            name: '定员人数',
            dataIndex: 'fixNumPerson',
            unit: '人',
            default: 12,
            price: null,
            min: 1,
            max: 100,
        },
        {
            name: '人工工资（不含福利）',
            dataIndex: 'wages',
            unit: '人/年',
            default: 80000,
            price: null,
            min: 50000,
            max: 1000000,
        },
        {
            name: '长期贷款利率',
            dataIndex: 'longLoanRate',
            unit: '%',
            default: 4.9,
            price: null,
            min: 0,
            max: 20,
        },
        {
            name: '短期贷款利率',
            dataIndex: 'shortLoanRate',
            unit: '%',
            default: 3.2,
            price: null,
            min: 0,
            max: 20,
        },
        {
            name: '风机质保期',
            dataIndex: 'deviceWarranty',
            unit: '年',
            default: 5,
            price: null,
            min: 1,
            max: 50,
        },
        {
            name: '材料费（质保期内）',
            dataIndex: 'warrantyInMaterial',
            unit: '元/kW',
            default: 5,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '材料费（质保期外）',
            dataIndex: 'warrantyOutMaterial',
            unit: '元/kW',
            default: 11,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '其他费',
            dataIndex: 'otherFee',
            unit: '元/kW',
            default: 30,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '保险费率',
            dataIndex: 'safeRate',
            unit: '%',
            default: 0.25,
            price: null,
            min: 0,
            max: 1,
        },
        {
            name: '修理费（质保期内）',
            dataIndex: 'warrantyInFix',
            unit: '元/kW',
            default: 16,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '修理费（质保期外）',
            dataIndex: 'warrantyOutFix',
            unit: '元/kM',
            default: 40,
            price: null,
            min: 0,
            max: 1000,
        },
        {
            name: '折旧年限',
            dataIndex: 'depreciationPeriod',
            unit: '年',
            default: 20,
            price: null,
            min: 5,
            max: 20,
        },
        {
            name: '折旧残值',
            dataIndex: 'depreciationResidual',
            unit: '%',
            default: 5,
            price: null,
            min: 0,
            max: 5,
        },
        {
            name: '永久征地费',
            dataIndex: 'landAcquisitionFee',
            unit: '元/亩',
            default: 246790,
            price: null,
            min: 25000,
            max: 800000,
        },
        {
            // name: '土地经济补偿费',
            name: '永久征地补偿费',
            dataIndex: 'landCompensation',
            unit: '元/亩/年',
            default: 213440,
            price: null,
            min: 2000,
            max: 250000,
        }, {
            name: '送出工程总费用',
            dataIndex: 'sendOutTotalPrice',
            unit: '万元',
            default: '系统确认升压站位置后自动计算', 
            // 填写升压站并且输入接入站位置，后台根据公式算出来一个默认值；未填写升压站但填写了接入站位置，默认值换成“系统确认升压站位置后自动计算”；未填写接入站位置，默认值换成0。用户调整值以调整值为准
            price: null,
            min: 100,
            max: 10000,
        },
    ]);
    // 详情唯一标识id
    const projectDetailID = ref('');
    // 表头常量
    const columns = ref([
        {
            title: '费用项',
            dataIndex: 'name',
            width: '28%',
            align: 'center',
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: '20%',
            align: 'center',
        },
        {
            title: '默认值',
            dataIndex: 'default',
            width: '27%',
            align: 'center',
        },
        {
            title: '调整值',
            dataIndex: 'price',
            width: '25%',
            align: 'center',
            isEdit: true,
            type: 1,
        },
    ]);
    // 是否扣减套餐次数
    const isDeductTimes = ref(false);
    const isDeductTimesRemark = ref('');
    onMounted(() => {
        eventBus.on('validateStep', onValidateStep);
    });
    onUnmounted(() => {
        eventBus.off('validateStep', onValidateStep);
    });
    // 监听当前是第几步骤
    const onValidateStep = async (step) => {
        if (step == 3) {
            await onInitParam();
            // 调整回显
            if (route.query.type == 'edit') {
                columns.value = [
                    {
                        title: '费用项',
                        dataIndex: 'name',
                        width: '28%',
                        align: 'center',
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        width: '22%',
                        align: 'center',
                    },
                    {
                        title: '调整值',
                        dataIndex: 'price',
                        width: '25%',
                        align: 'center',
                        isEdit: true,
                        type: 1,
                    },
                ];
                echoData();
                if (route.query.type == 'edit') {
                    checkAdjustProjectIsDeductTimesEvent();
                }
            } else {
                columns.value = [
                    {
                        title: '费用项',
                        dataIndex: 'name',
                        width: '28%',
                        align: 'center',
                    },
                    {
                        title: '单位',
                        dataIndex: 'unit',
                        width: '22%',
                        align: 'center',
                    },
                    {
                        title: '默认值',
                        dataIndex: 'default',
                        width: '25%',
                        align: 'center',
                    },
                    {
                        title: '调整值',
                        dataIndex: 'price',
                        width: '25%',
                        align: 'center',
                        isEdit: true,
                        type: 1,
                    },
                ];
                if (route.query.type == 'draft') {
                    echoData();
                }
            }
        }
    };
    // 调整阶段判断该项目是否需要扣减次数
    const checkAdjustProjectIsDeductTimesEvent = async () => {
        const data = await checkAdjustProjectIsDeductTimes(sessionStorage.getItem('projectID'));
        console.log(data, '验证套餐扣减接口');
        if (data && data.code == 200) {
            if (data.data) {
                isDeductTimes.value = data.data?.flag;
                isDeductTimesRemark.value = data.data?.remark;
            }
        }
    };
    // 第三步参数默认值显示
    const onInitParam = async () => {
        const data = await getThreeStepParam({
            id: sessionStorage.getItem('projectID'),
        });
        console.log(data);
        if (data && data.code == 200) {
            const requiredData = data.data;
            dataSource.value.map((ele) => {
                ele.default = requiredData[ele.dataIndex];
                // 送出工程总费用字段特殊处理 后台返回-1需要渲染成特殊文字
                if (ele.dataIndex == 'sendOutTotalPrice') {
                    ele.default = requiredData[ele.dataIndex] == -1 ? '系统确认升压站位置后自动计算' : requiredData[ele.dataIndex];
                }
            });
            // 如果是调整，需要渲染新的特殊字段（名称为动态）
            if (route.query.type == 'edit') {
            }
        }
    };
    const filterDataSource = (data) => {
        const originData = JSON.parse(JSON.stringify(data))
        return route.query.type == 'edit' ? originData : originData.filter((ele) => !ele.isEdit);
    };
    // 根据字段名称查找对应的index
    const getIndex = (dataIndex) => {
        const index = dataSource.value.findIndex((ele) => ele.dataIndex == dataIndex);
        return index;
    };
    // 调整数据回显
    const echoData = async () => {
        const data = await selectProjectInfoById({ id: sessionStorage.getItem('projectID') });
        if (data && data.code == 200) {
            const projectDetail = data.data.projectDetailDTO ? JSON.parse(JSON.stringify(data.data.projectDetailDTO)) : {};
            dataSource.value.map((ele) => {
                // if (projectDetail[ele.dataIndex] !== ele.default) {
                //     ele.price = projectDetail[ele.dataIndex];
                // }
                ele.price = projectDetail[ele.dataIndex];
                // 草稿状态下还要把默认和调整值一致的去除掉
                if (route.query.type == 'draft') {
                    if (projectDetail[ele.dataIndex] == ele.default) {
                        ele.price = null;
                    }
                    // 送出工程总费用字段特殊处理 后台返回-1需要渲染成特殊文字
                    if (ele.dataIndex == 'sendOutTotalPrice') {
                        ele.price = projectDetail[ele.dataIndex] == -1 ? null : projectDetail[ele.dataIndex];
                    }
                }
            });

            // 特殊字段（风电机组、塔筒、变压器、svg）数据处理
            const formatSpeParData = (sourceData, dataIndexArr) => {
                dataIndexArr.map((ele) => {
                    const index = getIndex(ele);
                    if (sourceData[ele]) {
                        const jsons = JSON.parse(sourceData[ele]);
                        if (index > -1) {
                            dataSource.value[index].isEdit = true;
                            dataSource.value[index].default = jsons.equipmentFee;
                            dataSource.value[index].name = jsons.name;
                            dataSource.value[index].unit = jsons.unit;
                            dataSource.value[index].price = jsons.equipmentFee;
                            // 主变压器数据需要单独设置最大值最小值；
                            if (ele == 'boosterJson') {
                                dataSource.value[index].min = jsons.equipmentFee * 0.95;
                                dataSource.value[index].max = jsons.equipmentFee * 1.1;
                            }
                        }
                    } else {
                        // 没有字段的，需要删除这条字段数据
                        dataSource.value.splice(index, 1);
                    }
                });
            };
            if (route.query.type == 'edit') {
                formatSpeParData(projectDetail, [
                    'windGroupJson',
                    'towerJson',
                    'boosterJson',
                    'svgGroupJson',
                ]);
            }
            projectDetailID.value = projectDetail.id || '';
        }
        console.log('fangshu', dataSource.value);
    };
    // 验证服务的有效性
    const checkPackage = async () => {
        let isEffective = true;
        await checkPackageEffective({
            companyId: sessionStorage.getItem('userId'),
            itemType: PACKAGE_TYPE.default, // 字符串类型 1风电可研 2光伏可研
        }).then((res) => {
            if (res.code == 200) {
                sessionStorage.setItem('isInitiate', res.data.isInitiate);
                if (res.data.isInitiate) {
                    // 可以去创建模板
                    isEffective = true;
                } else {
                    if (res.data.notInitiateType) {
                        messageType.value = res.data.notInitiateType;
                        TipsDialogVisible.value = true;
                    }
                    isEffective = false;
                }
            } else {
                // isShowLoading.value = false;
            }
        });
        return isEffective;
    };
    // 传数据到后台
    const postData = async (type, params) => {
        formLoading.value = true;
        try {
            const data =
                route.query.type == 'edit'
                    ? await updateFormData(params)
                    : await saveFormData(params);
            if (data && data.code == 200) {
                formLoading.value = false;
                message.success('操作成功！');
                if (type == 1) {
                    // 保存成草稿停留在当前页面
                } else {
                    router.push({ path: '/styleAI/reportResult' });
                }
            } else {
                formLoading.value = false;
                message.error(data.msg);
            }
        } catch (error) {
            formLoading.value = false;
        }
    };
    // 提交数据前数据处理操作
    const onBeforeSubmitForm = async (type) => {
        dataSource.value = editTableRef.value.dataList;
        // 用户行为日志--草稿,生成项目
        if (type == 1) {
            gtag('event', 'save_draft', {
                user_info_id: sessionStorage.getItem('userId'),
                project_info_id: sessionStorage.getItem('projectID'),
            });
        } else {
            gtag('event', 'build_project', {
                user_info_id: sessionStorage.getItem('userId'),
                project_info_id: sessionStorage.getItem('projectID'),
            });
        }
        const formateResult = {
            projectId: sessionStorage.getItem('projectID'),
        };
        editTableRef.value.dataList.map((ele) => {
            formateResult[ele.dataIndex] = ele.price || ele.price == 0 ? ele.price : ele.default;
        });
        // 送出工程总费用字段特殊处理
        formateResult.sendOutTotalPrice = formateResult.sendOutTotalPrice >= 0 ? formateResult.sendOutTotalPrice : -1;
        const params = {
            id: sessionStorage.getItem('projectID'),
            step: 3,
            uniqueIdentification: sessionStorage.getItem('uniqueIdentification'),
            projectAdjustOneStepFlag: false,
            projectAdjustFlag: route.query.type == 'edit',
            projectDetail: formateResult,
        };
        // 如果是草稿传特殊字段
        type == 1 && (params.isDraft = true);
        if (route.query.type == 'draft') {
            params.projectDetail.id = projectDetailID.value;
        }
        if (route.query.type == 'edit') {
            params.projectId = sessionStorage.getItem('projectID');
            params.projectDetail.id = projectDetailID.value;
            params.projectAdjustId = sessionStorage.getItem('originProjectID');
            const formatSpeParParams = (parsArr) => {
                parsArr.map((ele) => {
                    const index = getIndex(ele);
                    if (index > -1) {
                        params.projectDetail[ele] = JSON.stringify({
                            name: dataSource.value[index].name,
                            equipmentFee:
                                dataSource.value[index].price || dataSource.value[index].default,
                            unit: dataSource.value[index].unit,
                        });
                    }
                });
            };
            // 四个字段特殊处理
            formatSpeParParams(['windGroupJson', 'towerJson', 'boosterJson', 'svgGroupJson']);
        } else {
            // 新增创建、草稿编辑不传这几个特殊字段数据
            ['windGroupJson', 'towerJson', 'boosterJson', 'svgGroupJson'].forEach((ele) => {
                delete params.projectDetail[ele];
            });
        }
        params.obsPath = sessionStorage.getItem('obsFilePath');
        console.log(params);
        // debugger;
        if (route.query.type == 'edit') {
            if (isDeductTimes.value) {
                Modal.confirm({
                    title: '套餐提示',
                    content: h(
                        'div',
                        {
                            class: 'softdog-modal-content',
                        },
                        [
                            h(
                                'p',
                                '当前调整项目因达到扣减次数条件将扣减服务1个次数，确定调整该项目么？',
                            ),
                            h(
                                'p',
                                {
                                    style: 'margin-top: 10px',
                                },
                                '满足条件为：',
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                    style: 'margin-top: 1px',
                                },
                                isDeductTimesRemark.value,
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                },
                                [
                                    '',
                                    h('div', [
                                        '平台客服：',
                                        h(
                                            'span',
                                            {
                                                class: 'self-bar',
                                            },
                                            '155-6259-8617',
                                        ),
                                    ]),
                                ],
                            ),
                        ],
                    ),
                    okText: '确定调整',
                    cancelText: '再考虑下',
                    onOk() {
                        // 调整项目需要先判断是否需要扣除套餐次数，如果需要扣除，要验证套餐是否有效，有效再去发送数据到后台
                        checkPackage().then((res) => {
                            if (res) {
                                postData(type, params);
                            }
                        });
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                postData(type, params);
            }
        } else {
            postData(type, params);
        }
    };
</script>
<style lang="scss" scoped>
    @import './ThreeStep.scss';
</style>
